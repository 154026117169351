import React from 'react';
import Email from '../components/Email';
import { StaticQuery, graphql } from 'gatsby';

const Call = props => (
  <div className="call">
    <div className="call-box-top">
      <div className="call-phone">
        <strong>Teléfono: </strong>
        {props.data.site.siteMetadata.contact.phone}
      </div>
      <div className="call-email">
        <strong>Email: </strong>
        <Email localpart={props.data.site.siteMetadata.contact.email.localpart} domain={props.data.site.siteMetadata.contact.email.domain} size="16"/>
      </div>
      
    </div>
    {props.button && (
      <div className="call-box-bottom">
        <a href="/contacto/" className="button">
          Contacto
        </a>
      </div>
    )}
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            contact {
              email {
                localpart
                domain
              }
              phone
              address
            }
          }
        }
      }
    `}
    render={data => <Call button={props.button} data={data} />}
  />
);
